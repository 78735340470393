import { computed, ComputedRef, onUnmounted, Ref, ref } from "vue";
// This is the only place where we want to allow the "vue-i18n" package and we wrap its functionality for further usage
// eslint-disable-next-line no-restricted-imports
import { type DateTimeOptions, type IntlDateTimeFormat, useI18n } from "vue-i18n";
import { Rational, Timestamp } from "@ui/clients";

declare global {
    interface Messages {}
}

export type MessageValue =
    | undefined // will be rendered as empty string
    | null // will be rendered as empty string
    | string
    | Date // same as { d: date }
    | number // same as ".toString()"
    | bigint // same as ".toString()"
    | Rational // same as { n: rat }
    | { m: MessageOrId } // eslint-disable-line wux/msg-types
    | { d: Parameters<DateFormatter>[0]; opts?: Parameters<DateFormatter>[1] }
    | { t: Parameters<TimeFormatter>[0]; opts?: Parameters<TimeFormatter>[1] }
    | { n: Parameters<NumberFormatter>[0]; opts?: Parameters<NumberFormatter>[1] }
    | { rtf: Parameters<RelativeTimeFormatter>[0]; opts?: Parameters<RelativeTimeFormatter>[1] };
type MessageValues = Record<string, MessageValue>;

export type MessageId = string & { __: symbol };
export type Message = { id: MessageId; values?: MessageValues };
export type MessageOrId = Message | MessageId;

// using union with string to filter "number" keys
type TypeSafeMessageId = keyof Messages & string;
type TypeSafeMessage = { id: TypeSafeMessageId; values?: MessageValues };
type TypeSafeMessageOrId = TypeSafeMessage | TypeSafeMessageId;
export type MessageFormatter = (msg: MessageOrId | TypeSafeMessageOrId) => string;
export type DateFormatter = (
    value: string | number | Date | Timestamp,
    format?: DateTimeFormats | DateTimeOptions,
) => string;
export type TimeFormatter = (value?: Time, opts?: { withSeconds?: boolean; force24h?: boolean }) => string;
export type OptMessageFormatter = (msg?: MessageOrId | TypeSafeMessageOrId) => string | undefined;
export type RawMessageFormatter = (msg?: MessageOrId | TypeSafeMessageOrId, raw?: string) => string | undefined;
export type NumberFormatter = (
    value: number | bigint | Rational,
    opts?: { precision?: number; noTrailingZeros?: boolean },
) => string;
// if `to` is not provided, new Date() is used
export type RelativeTimeFormatter = (
    from: Date | Timestamp,
    opts?: { to?: Date | Timestamp; showAccurateSeconds?: boolean },
) => string;
export type ReadonlyState = {
    locale: ComputedRef<string>;
    isUS: ComputedRef<boolean>;
};
export type MessageFormatters = {
    t: TimeFormatter;
    d: DateFormatter;
    m: MessageFormatter;
    optM: OptMessageFormatter;
    rawM: RawMessageFormatter;
    n: NumberFormatter;
    rtf: RelativeTimeFormatter;
};
export type Time = {
    minutes: number;
    hours: number;
    seconds?: number;
};

/**
 * ATTENTION: This proxy function is necessary to work with type-safe translation keys and use those in Vue props
 *            Otherwise, the complexity of the union of all translation keys and the complexity of the Vue types exceeds the
 *            defined complexity limits of TypeScript. That's why we couldn't update to higher versions than vue@3.3.8 anymore.
 *
 * Identify function that allows to creates a translatable message
 */
export const __ = (msg: TypeSafeMessageId) => msg as MessageId;

export const DATE_TIME_FORMATS = {
    date: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    },
    dateWithoutYear: {
        month: "2-digit",
        day: "2-digit",
    },
    dateAndTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    },
    timestamp: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
    },
    dateAndTimeWithoutYear: {
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    },
    timeWithoutDate: {
        hourCycle: "h24",
        minute: "2-digit",
        hour: "2-digit",
    },
    timeWithSecondsWithoutDate: {
        hourCycle: "h24",
        minute: "2-digit",
        hour: "2-digit",
        second: "2-digit",
    },
} satisfies IntlDateTimeFormat;

export type DateTimeFormats = keyof typeof DATE_TIME_FORMATS;
const EN_US_LOCALE = "en-US";
const GERMAN_LOCALE = "de";

// exported for test utilities
export const _n = (
    value: number | bigint | Rational,
    { precision, noTrailingZeros }: { precision?: number; noTrailingZeros?: boolean } = {},
    isUS = false,
) => {
    const prec = precision ?? (typeof value === "bigint" ? 0 : 2);
    const rat = value instanceof Rational ? value : Rational.toRat(value as number);
    // separate the sign from the calculations
    const sign = rat.isNegative() ? "-" : "";
    // remove the sign from the number
    const [integral, precisionFraction] = rat.toFixed(prec).replace("-", "").split(".");
    const fraction = noTrailingZeros ? precisionFraction?.replace(/0*$/, "") : precisionFraction;
    const c = 3 - (integral.length % 3);
    const mc = c === 3 ? 0 : c;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const parts = ("$".repeat(mc) + integral).match(/.{1,3}/g)!;
    const formattedIntegral = sign + parts.join(isUS ? "," : ".").substring(mc);
    return fraction ? `${formattedIntegral}${isUS ? "." : ","}${fraction}` : formattedIntegral;
};

export const useMsgFormatter = (): MessageFormatters & ReadonlyState => {
    const i18n = useI18n();
    const locale = computed(() => i18n.locale.value);
    const isUS = computed(() => i18n.locale.value === EN_US_LOCALE);
    const iRtf = new Intl.RelativeTimeFormat(locale.value, { style: "long", numeric: "auto" });

    const d: DateFormatter = (value, format = "date") => {
        const usedValue = value instanceof Timestamp ? Timestamp.toDate(value) : value;
        const usedFormat = typeof format === "string" ? DATE_TIME_FORMATS[format] : format;
        return i18n.d(usedValue, usedFormat, isUS.value ? EN_US_LOCALE : GERMAN_LOCALE);
    };
    const t: TimeFormatter = (value?: Time, { withSeconds, force24h } = {}) => {
        if (!value || isNaN(+value.hours) || isNaN(+value.minutes) || isNaN(+(value.seconds || 0))) {
            return "";
        }
        const isUs = !force24h && isUS.value;
        const result = i18n.d(
            new Date(`01.01.1970 ${value.hours}:${value.minutes}${withSeconds ? `:${value.seconds || "0"}` : ""}`),
            {
                hourCycle: isUs ? "h12" : "h23",
                minute: "2-digit",
                hour: "2-digit",
                second: withSeconds ? "2-digit" : undefined,
            },
            isUs ? EN_US_LOCALE : GERMAN_LOCALE,
        );
        // return only the time part, if it has a date
        return result.split(", ")[1] || result;
    };
    const rtf: RelativeTimeFormatter = (from, { to = new Date(), showAccurateSeconds } = {}): string => {
        if (from instanceof Timestamp) {
            from = Timestamp.toDate(from);
        }
        if (to instanceof Timestamp) {
            to = Timestamp.toDate(to);
        }
        const smallestRelativeTime = getSmallestRelativeTime(from, to, showAccurateSeconds);
        return iRtf.format(smallestRelativeTime.value, smallestRelativeTime.unit);
    };
    const optM: OptMessageFormatter = (msg) => (msg ? m(msg) : undefined);
    const n: NumberFormatter = (value, opts) => _n(value, opts, isUS.value);
    const _processMsgValue = (value: MessageValue) => {
        if (value === undefined || value === null) return "";
        if (typeof value === "bigint") return value.toString();
        if (value instanceof Date) return d(value);
        if (value instanceof Rational) return n(value);
        if (typeof value === "object") {
            if ("m" in value) return m(value.m);
            if ("d" in value) return d(value.d, value.opts);
            if ("t" in value) return t(value.t, value.opts);
            if ("n" in value) return n(value.n, value.opts);
            if ("rtf" in value) return rtf(value.rtf, value.opts);
        }
        return value;
    };
    const _processMsgValues = (msgValues: MessageValues) =>
        Object.fromEntries(Object.entries(msgValues).map(([name, value]) => [name, _processMsgValue(value)]));
    const m: MessageFormatter = (msg) => {
        if (typeof msg === "string") return i18n.t(msg);
        if (msg.values) return i18n.t(msg.id, _processMsgValues(msg.values));
        return i18n.t(msg.id);
    };
    const rawM: RawMessageFormatter = (msg, raw) => (msg !== undefined ? m(msg) : raw);
    return { m, d, t, optM, rawM, n, rtf, locale, isUS };
};

export type TimeUnit = "second" | "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";

export const getSmallestRelativeTime = (
    from: Date,
    to: Date,
    showAccurateSeconds?: boolean,
): {
    value: number;
    unit: TimeUnit;
} => {
    const secondsInMinute = 60;
    const minutesInHour = 60;
    const hoursInDay = 24;
    const daysInWeek = 7;
    const weeksInMonth = 4;
    const monthsInQuarter = 3;
    const quartersInYear = 4;
    const monthsInYear = 12;
    const msPerSecond = 1e3;

    const secondsDiff: number = Math.round((from.getTime() - to.getTime()) / msPerSecond);
    if (Math.abs(secondsDiff) < secondsInMinute) {
        if (!showAccurateSeconds) return { value: 0, unit: "second" }; // we prefer to display "now" if difference is < 1min
        return { value: secondsDiff, unit: "second" };
    }

    const minutesDiff = Math.round(secondsDiff / secondsInMinute);
    if (Math.abs(minutesDiff) < minutesInHour) {
        return { value: minutesDiff, unit: "minute" };
    }

    const hoursDiff = Math.round(minutesDiff / minutesInHour);
    if (Math.abs(hoursDiff) < hoursInDay) {
        return { value: hoursDiff, unit: "hour" };
    }

    const dayDiff = Math.round(hoursDiff / hoursInDay);
    if (Math.abs(dayDiff) < daysInWeek) {
        return { value: dayDiff, unit: "day" };
    }

    const weekDiff = Math.round(dayDiff / daysInWeek);
    if (Math.abs(weekDiff) < weeksInMonth) {
        return { value: weekDiff, unit: "week" };
    }

    const monthDiff = Math.round(weekDiff / weeksInMonth);
    if (Math.abs(monthDiff) < monthsInQuarter) {
        return { value: monthDiff, unit: "month" };
    }

    const quarterDiff = Math.round(monthDiff / monthsInQuarter);
    if (Math.abs(quarterDiff) < quartersInYear) {
        return { value: quarterDiff, unit: "quarter" };
    }

    const yearDiff = Math.round(monthDiff / monthsInYear);
    return {
        value: yearDiff,
        unit: "year",
    };
};

export const useDateTime = (
    time: Ref<Date | Timestamp | undefined>,
    refreshTimeMs: number = 1000 * 30,
    format: DateTimeFormats | DateTimeOptions = DATE_TIME_FORMATS.dateAndTime,
): ComputedRef<{ dateTime: string; elapsedTime: string } | undefined> => {
    const { d, rtf } = useMsgFormatter();
    const counter = ref(1);

    // counter is used to make sure the computed ref is recalculated every refreshTimeMs, thus updating the elapsedTime
    const interval = setInterval(() => counter.value++, refreshTimeMs);
    onUnmounted(() => {
        clearInterval(interval);
    });

    return computed(() => {
        if (!counter.value) return;
        if (time.value === undefined) {
            return;
        }
        const value = time.value instanceof Timestamp ? Timestamp.toDate(time.value) : time.value;
        return {
            dateTime: d(value, format),
            elapsedTime: rtf(value),
        };
    });
};
