<script lang="ts" setup>
import { __, Icons, Shortcut, WuxProfileMenu, WuxProfileMenuItem } from "@ui/components";
import { LOGOUT_PATH } from "../auth/paths";
import { useUserStore } from "../auth/user";
import { WaWiLanguage } from "../i18n/language/language";
import { WaWiModule } from "../module/module";
import AboutContent from "./AboutContent.vue";
import { Dialog } from "./Dialog";
import { DirtyGuard } from "./DirtyGuard";
import { GuidedTour, useGuidedTourStore } from "./GuidedTour";
import { ShortcutInfo } from "./shortcuts";

const props = defineProps<{
    shortcut: Shortcut;
    open?: boolean;
}>();

const emit = defineEmits<{ (e: "update:open", isOpen: boolean): void }>();

const userStore = useUserStore();
const guidedTourStore = useGuidedTourStore();
const language = WaWiLanguage.getCurrentOption();

const logout = async () => {
    if (!(await DirtyGuard.isLogoutConfirmed())) return;
    window.location.assign(LOGOUT_PATH);
};

const goToProfilePage = (metaOrCtrl: boolean) => {
    WaWiModule.setLaunchpad({ subPath: "/user", newTab: metaOrCtrl });
};
const onAbout = () =>
    Dialog.info({
        headerMsg: __("ui.libs.about.title"),
        content: { component: AboutContent },
    });
</script>

<template>
    <WuxProfileMenu :shortcut="props.shortcut" :open="props.open" @update:open="emit('update:open', $event)">
        <WuxProfileMenuItem
            :labelMsg="__('ui.libs.profile-menu.about')"
            :icon="Icons.circle_information"
            @click="onAbout"
        />
        <WuxProfileMenuItem
            v-if="userStore.isLoggedIn"
            :label="userStore.displayName"
            :icon="Icons.profile"
            @click="goToProfilePage"
        >
            <div>
                <small>{{ userStore.email }}</small>
            </div>
            <div>
                <small>{{ language.label }}</small>
            </div>
        </WuxProfileMenuItem>
        <WuxProfileMenuItem
            :labelMsg="__('ui.libs.profile-menu.shortcuts')"
            :icon="Icons.keyboard"
            @click="ShortcutInfo.show"
        />
        <WuxProfileMenuItem
            v-if="guidedTourStore.steps.length"
            :labelMsg="__('ui.libs.profile-menu.guided-tour-start')"
            :icon="Icons.education"
            @click="GuidedTour.start"
        />
        <WuxProfileMenuItem
            v-if="userStore.isLoggedIn"
            :labelMsg="__('ui.libs.profile-menu.logout')"
            :icon="Icons.logout"
            @click="logout"
        />
    </WuxProfileMenu>
</template>
